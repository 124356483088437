import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


// Using Foundations built-in MediaQuery to test screen size
// Then undo all the attributes, classes, and inline styles that data-responsive-menu add when using accordion menus.

if (Foundation.MediaQuery.atLeast('medium')) {
    // True if medium or large
    // False if small
    $(".menu").removeAttr('data-accordion-menu').removeClass('accordion-menu');
    $(".menu li.is-accordion-submenu-parent").removeClass("is-accordion-submenu-parent");
    $(".menu ul.is-accordion-submenu").removeClass("is-accordion-submenu").removeAttr('style');
}